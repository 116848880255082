@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");

.menu-item {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

.menu-item.active {
  color: #fff;
}

.menu-item:not(.active) {
  color: #909090;
}

.menu-item .search-icon {
  display: inline-flex;
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: 2px;
}

.menu-item .search-text {
  display: inline-flex;
  vertical-align: middle;
}

.psychedelic-title {
  font-size: 3rem;
  font-family: "Orbitron", sans-serif;
}

@media (max-width: 640px) {
  .menu-item {
    font-size: 16px;
  }

  .menu-item .search-icon {
    margin-right: 2px;
  }
}
