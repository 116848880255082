@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
.track-title {
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  user-select: text;
}

.track-description {
  color: #909090;
  font-size: 1 rem;
  margin-top: -4px;
}
.heading {
  font-family: "Orbitron", sans-serif;
  font-size: 1rem;
}
.tracks-container {
  padding-top: 2rem;
  padding-bottom: 7rem;
}
.tracks-grid-wrapper::-webkit-scrollbar {
  display: none;
}

.tracks-grid-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
