* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #333;
  background-color: black;
}
