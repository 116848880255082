@tailwind base;
@tailwind components;
@tailwind utilities;

.audio-player {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 72px;
  padding: 0 30px;
  background-color: black;
  color: #fff;
  box-sizing: border-box;
}

.audio-player img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
}

.left-controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  user-select: none;
}

.center-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  user-select: none;
}

.right-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  user-select: none;
}

.volume-container {
  position: relative;
  cursor: pointer;
}

.volume-slider-container {
  display: flex;
  align-items: center;
  position: absolute;
  left: -135px;
  bottom: 50%;
  transform: translateY(50%);
  padding-left: 6px;
  cursor: default;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.volume-slider-container.visible {
  opacity: 1;
}

.volume-slider {
  height: 3px;
  width: 100px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background: #fff;
}

.volume-slider::-webkit-slider-thumb {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 50%;
}

.volume-slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 50%;
}

.center-controls {
  flex-direction: row;
}

.progress-bar-container {
  position: absolute;
  width: 100%;
  bottom: -3px;
  left: 0;
  padding: 8px 0;
}

.progress-bar {
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #bdbdbd;
  background: linear-gradient(
    to right,
    #f00 0%,
    #f00 var(--progress-percentage, 0%),
    #fff var(--progress-percentage, 0%),
    #fff 100%
  );
  height: 2px;
  cursor: pointer;
  position: absolute;
  bottom: 75px;
  left: 0;
  transition: height 0.2s;
}

.progress-bar-mobile {
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #bdbdbd;
  background: linear-gradient(
    to right,
    #fff 0%,
    #fff var(--progress-percentage, 0%),
    #909090 var(--progress-percentage, 0%),
    #909090 100%
  );
  height: 2px;
  cursor: pointer;
  position: absolute;
  top: 71px;
  left: 0;
  transition: height 0.2s;
}

.progress-bar-mobile::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
}

.progress-bar-mobile::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
}

.progress-bar:hover {
  height: 3px;
}

.progress-bar:focus {
  outline: none;
}

.progress-bar::-webkit-slider-thumb {
  position: relative;
  z-index: 1;
  width: 10px;
  height: 10px;
  appearance: none;
  background-color: #f00;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.progress-bar::-moz-range-thumb {
  width: 10px;
  height: 10px;
  appearance: none;
  background-color: #f00;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
  border: none;
}

.progress-bar:hover::-webkit-slider-thumb {
  opacity: 1;
}

.progress-bar:hover::-moz-range-thumb {
  opacity: 1;
}

.progress-time {
  font-size: 0.8em;
  background-color: black;
  padding: 2px 5px;
  border-radius: 3px;
  bottom: 100%;
  margin-bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  white-space: nowrap;
}

.mobile-controls {
  display: none;
}

.control-wrapper {
  display: inline-flex;
  padding: 15px;
  box-sizing: content-box;
}

.track-title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  user-select: text;
}

.track-description {
  color: #909090;
  font-size: 1rem;
  margin-top: -4px;
}

.progress-bar-container-mobile {
  display: none;
}

.marquee-container {
  width: 210px;
}

.marquee {
  min-width: 18rem;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.slide-in {
  animation: slideIn 0.5s ease-out;
}

@media (max-width: 768px) {
  .audio-player {
    padding: 0 10px;
  }
  .progress-bar-container {
    display: none;
  }
  .progress-bar-container-mobile {
    display: block;
  }
  .right-controls,
  .timer,
  .left-controls {
    display: none;
  }
  .center-controls {
    justify-content: flex-start;
  }
  .mobile-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0.5;
    user-select: none;
  }
}
